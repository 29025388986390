@import '~ethos-design-system/src/components/Media/Media.scss';
@import '../EstimateWidget/Mixins.scss';

.duckegg {
  background-color: var(--BrandDuckegg);
}

.estimateExperienceContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.supportBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-phone-and-small-tablet {
    padding: var(--Space-32) 0;
  }

  @include for-large-tablet-and-up {
    padding: var(--Space-48) 0;
  }

  .supportCopy {
    .phoneAndSmallTablet {
      a {
        display: flex;
      }

      .phoneIcon {
        right: 0;
        margin-top: -4px;
        margin-left: var(--Space-4);
      }

      @include for-large-tablet-and-up {
        display: none;
      }
    }
    .smallLaptopAndUp {
      @include for-phone-and-small-tablet {
        display: none;
      }
    }

    .phoneNumber {
      color: var(--GraySecondary--opaque);
      text-decoration: underline;
    }
  }
}

.faqContainer {
  display: flex;
}

.faq {
  width: 100%;

  @include for-phone-and-tablet {
    padding: var(--Space-48) var(--Space-24);
  }

  @include for-small-laptop-only {
    padding-top: var(--Space-48);
  }

  @include for-large-laptop-and-up {
    padding-top: var(--Space-56);
  }

  @include for-desktop-only {
    padding-top: var(--Space-56);
  }
}
